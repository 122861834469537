<template>
  <div class="tag_group">
    <div class="top_add_tag">
      <el-button class="add_tag" @click="onAddTagGroup" v-if="!editVisible">添加</el-button>
      <div class="outer_edit" v-else>
        <transition name="el-fade-in">
            <div class="existed_tag category_tag" v-if="ExistedTag">{{errorWarn}}</div>
        </transition>
        <transition name="el-fade-in">
          <div class="success_tag category_tag" v-if="addGroupSucc">标签组添加成功！</div>
        </transition>
        <div >
          <el-input
            class="input-new-tag"
            v-model="addValue"
            ref="saveTagInput"
            size="small"
            placeholder="添加标签组"
            :maxlength="20"
            v-focus
            @keyup.enter.native="onConfirm"
          >
          </el-input>
        </div>
        <div class="operation">
          <i class="iconfont icon-duihao" @click="onConfirm"></i>
          <i class="iconfont icon-cuo" @click="onCancel"></i>
        </div>
      </div>
    </div>

    <div class="tag_content">
      <div v-for="(item,i) in tagGroupList" :key="i">
          <div :class="['outer_title_box',item.innerShow?'is-active':'']" :id="'box'+ item.tag_group_id">
            <div @click="showInnerTag(item)" v-if="!item.nameReset">
              <i class="el-icon-arrow-right"></i>
              <p class="outer_title" @mouseenter="tagOper(item.tag_group_id)" :id="item.tag_group_id">{{item.tag_group_name}}
                <span class="tag_group_operation">
                    <p @click.stop="resetName(item.tag_group_id,item.tag_group_name)">重命名</p>
                    <p @click.stop="deleteGroup(item.tag_group_id,'',1)">删除</p>
                  </span>
                <transition name="el-fade-in">
                  <div class="existed_tag category_tag" v-if="item.groupExistedTag" style="left:0;right: auto;top: -20px;">{{editGroupWarn}}</div>
                </transition>
                <transition name="el-fade-in">
                  <div class="success_tag category_tag" v-if="item.editGroupSucc" style="left:0;right: auto;top: -20px;">标签组重命名成功！</div>
                </transition>
              </p>
            </div>
            <div style="position:relative;text-align:left;line-height:48px;display:inline-block;" v-else>
              <el-input
                class="input-new-tag"
                v-model="item.editValue"
                ref="resetTagInput"
                size="small"
                style="line-height:48px;margin-bottom: 0;width:140px;"
                @click.stop.native="onFocus"
                @keyup.enter.native="onConfirmGroup(item)"
                @keyup.enter.stop.native="onFocus"
                @keydown.enter.stop.native="onFocus"
                @keyenter.enter.stop.native="onFocus"
                :maxlength="20"
                v-focus
              >
              </el-input>
              <div class="operation" style="line-height:48px;vertical-align:middle;margin-bottom: 0;">
                <i class="iconfont icon-duihao" @click.stop="onConfirmGroup(item)"></i>
                <i class="iconfont icon-cuo" @click.stop="onCancelGroup(item)"></i>
              </div>
            </div>
          <div class="tag_list" v-if="item.innerShow && item.tag_list.length">
              <div class="tag" v-for="(taglist,j) in item.tag_list" :key="j">
                <div class="tag_title" @click.stop="tagClick(item.tag_group_id,taglist.tag_id)" v-if="!taglist.tagNameReset" :id="taglist.tag_id">
                  {{taglist.tag_name}}（{{taglist.count}}）
                  <div class="pop_list" v-show="taglist.tagPop">
                    <!-- <p @click="searchTag(taglist.tag_id)">搜索包含本标签素材</p> -->
                    <p @click="deleteGroup(item.tag_group_id,taglist.tag_id,2)">从该组移除</p>
                    <p @click.stop="resetTagName(taglist)">重命名</p>
                    <p @click="deleteGroup(item.tag_group_id,taglist.tag_id,3)">删除</p>
                  </div>
                  <transition name="el-fade-in">
                    <div class="existed_tag category_tag" v-if="taglist.tagExistedTag" style="left:0;right: auto;top: -31px;">{{editErrorWarn}}</div>
                  </transition>
                  <transition name="el-fade-in">
                    <div class="success_tag category_tag" v-if="taglist.resetTagSucc" style="left:0;right: auto;top: -31px;">标签重命名成功！</div>
                  </transition>
                </div>

                <div style="position:relative;text-align:right;line-height:32px;margin-right: 15px;" v-else>
                  <!-- <div class="existed_tag category_tag" v-if="tagExistedTag" style="right: 62px;top: -31px;">该标签已存在</div> -->
                  <el-input
                    class="input-new-tag"
                    v-model="taglist.resetTagValue"
                    ref="saveTagInput"
                    size="small"
                    style="line-height:32px;margin-bottom: 0;width:140px;margin-left:0;"
                    @click.stop.native="onFocus"
                    @keyup.enter.native="onConfirmTag(taglist,item.tag_group_id)"
                    @keydown.enter.stop.native="onFocus"
                    @keyenter.enter.stop.native="onFocus"
                    :maxlength="20"
                    v-focus
                  >
                  </el-input>
                  <div class="operation" style="line-height:32px;vertical-align:middle;margin-bottom: 0;">
                    <!-- <i class="iconfont icon-duihao" @click.stop="onConfirmTag(taglist.tag_id,taglist.resetTagValue)"></i> -->
                    <i class="iconfont icon-duihao" @click.stop="onConfirmTag(taglist,item.tag_group_id)"></i>
                    <i class="iconfont icon-cuo" @click.stop="onCancelTag(taglist)"></i>
                  </div>
                </div>
              </div>
          </div>
          </div>
      </div>
      <noResult v-if="ifNodata" iconUrl="icon-meiyousucai" iconSty="font-size:80px" textSty="font-size:18px" noResultText="暂无数据"></noResult>
    </div>

    <el-dialog
        title="提 示"
        :visible.sync="warningDialog"
        width="400px"
        top="30vh"
        custom-class="delete-dialog"
        :show-close="true"
        :before-close="warningClose">
        <div class="warning_content">
          <div>
            {{dialogWarning}}
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="delConfirm">是</el-button>
          <el-button @click="warningDialog = false">否</el-button>
        </span>
    </el-dialog>

  </div>
</template>
<script>
import $ from 'jquery'
import noResult from '../component/noResult.vue'
export default {
  inject:['reload'],
  components: {
      noResult,
  },
  data () {
    return {
      activeNames: [],
      editVisible: false,
      ExistedTag: false, // 标签组添加报错显示隐藏控制
      addValue: '', // 添加标签组值
      editErrorWarn: '', // 标签重命名提示语
      warningDialog: false,
      addGroupSucc: false, // 标签组添加成功提示语
      errorWarn: '', // 添加标签组报错提示语
      editGroupWarn: '', // 标签组重命名报错提示语
      tagGroupList: [], // 标签组列表
      deleTagGroupId: '', // 要删除的标签组的id
      delTagId: '', // 要删除的标签的id
      dialogWarning: '', // 弹出框的文案
      operDialog: '', // 弹出框操作
      ifNodata: false,
    }
  },
  directives: {
	    focus: {
	      inserted: function (el) {
	        el.querySelector('input').focus()
	      }
	    }
  },
  computed: {
    tagGroupPage:{
      get() {
        return this.$store.state.tagGroup
      },
      set(val){
          // this.$store.commit('tagGroupPage', val)
      }

    }
  },
  watch: {
    tagGroupList(newVal,oldVal) {
      // console.log()
      this.$nextTick(()=>{
        sessionStorage.getItem("tag_group_id")?this.goAnchor("#box" + sessionStorage.getItem("tag_group_id")):''
      })
    },
    tagGroupPage(val) {
      if(val == true){
        this.reload();
      }
    },
  },
  created () {
    this.$store.commit('tagGroupPage', false)
    sessionStorage.removeItem("tag_group_id")
    // 标签组获取
    this.getTagGroup()

  },
  mounted () {
    const that = this
    document.addEventListener('click', function (e) {
        that.tagGroupList.forEach((item, i) => {
          item.tag_list.forEach((list, j) => {
            that.$forceUpdate()
            that.$set(list, 'tagPop', false)
          })
        })
    })

  },
  methods: {
    tagOper(id) {
      let tag_content = $(".tag_content")
      let tag_contentT = tag_content.offset().top + $(".tag_content").outerHeight()
      $(".outer_title").each((i,item)=>{
        let outer_titleT = $(item).offset().top + $(item).outerHeight()
        let tag_oper = $(item).find(".tag_group_operation")

        if($(item).attr("id") == id){
            if(tag_contentT - outer_titleT < 56) {
              $(tag_oper).css({'bottom':'12px','top':'auto'})
            }else{
              $(tag_oper).css({'top':'12px','bottom':'auto'})
            }
        }
      })

    },
    showInnerTag(item) {
      this.tagGroupList.forEach(item1 => {
        item1.tag_group_id == item.tag_group_id ? item.innerShow = !item.innerShow : ''
      })
    },
    submit() {
      // return false
      // console.log('submit')
    },
    handleChange (val) {
      // console.log(val)
    },
    resetName (id, name) {
      this.tagGroupList.forEach(item => {
        item.tag_group_id == id ? item.editValue = name : ''
        item.tag_group_id == id ? item.nameReset = true : ''
      })
    },
    deleteGroup (tag_group_id, tag_id, num) {
      // num： 1：标签组删除，2：把标签从标签组中移除，3：删除标签
      this.deleTagGroupId = tag_group_id
      this.delTagId = tag_id
      this.operDialog = num
      switch (num) {
        case 1:
          this.dialogWarning = '您确定删除该标签组吗？'
          break
        case 2:
          this.dialogWarning = '您确定将该标签移出标签组吗？'
          break
        case 3:
          this.dialogWarning = '您确定删除该标签吗？'
          break
      }

      this.warningDialog = true
    },
    onAddTagGroup () {
      this.editVisible = true
      this.addValue = ''
    },
    onConfirm () {
      if (this.addValue.trim() == '') {
        this.errorWarn = '标签组不能为空'
        this.ExistedTag = true
        setTimeout(() => {
          this.ExistedTag = false
        }, 3000)
        return
      }
      this.addTagGroup()
    },

    onConfirmGroup (item) {
      if (item.editValue.trim() == '') {
        this.editGroupWarn = '标签组不能为空'
        item.groupExistedTag = true
        setTimeout(() => {
          item.groupExistedTag = false
        }, 3000)
        return
      }
      this.editGroup(item)
    },
    onCancel () {
      this.editVisible = false
      this.ExistedTag = false
      this.addGroupSucc = false
    },
    onCancelGroup (item) {
      this.tagGroupList.forEach(item1 => {
        item1.tag_group_id == item.tag_group_id ? item1.nameReset = false : ''
      })
      item.groupExistedTag = false
    },
    onConfirmTag (taglist,tag_group_id) {
      if (taglist.resetTagValue.trim() == '') {
        this.editErrorWarn = '标签不能为空'
        taglist.tagExistedTag = true
        setTimeout(() => {
          taglist.tagExistedTag = false
        }, 3000)
        return
      }
      this.sendResetTagName(taglist,tag_group_id)
    },
    onCancelTag (taglist) {
      this.$forceUpdate()
      this.$set(taglist, 'tagNameReset', false)
      taglist.tagExistedTag = false
      this.tagGroupList.forEach((item, i) => {
          item.tag_list.forEach((list, j) => {
            // this.$forceUpdate()
            this.$set(list, 'tagPop', false)
          })
      })
    },
    onFocus (e) {
      // console.log(e)
    },
    searchTag (id) {
      this.$router.push({
        name: "allrourse",
        params:{tag_id:id}
      });
    },
    tagClick (tag_group_id, tag_id) {
       // ---------------对弹出框做自适应-----
       let tag_content = $(".tag_content")
       let tag_contentL = tag_content.offset().left + $(".tag_content").outerWidth()
       let tag_contentT = tag_content.offset().top + $(".tag_content").outerHeight()
       $(".tag_title").each((i,item)=>{
        if($(item).attr("id") == tag_id){

          let tag_titleL = $(item).offset().left + $(item).outerWidth()
          let tag_titleT = $(item).offset().top + $(item).outerHeight()
          let pop_list = $(item).find(".pop_list")

          if((tag_contentL - tag_titleL) < 144){
            $(pop_list).css({'left':'-145px','right':'auto'})
          }else{
            $(pop_list).css({'right':'-145px','left':'auto'})
          }
          if((tag_contentT - tag_titleT)< 132){
            $(pop_list).css({'bottom':'12px','top':'auto'})
          }else{
            $(pop_list).css({'top':'12px','bottom':'auto'})
          }

        }
       })
      //  -----------对弹出框做自适应-----

      this.tagGroupList.forEach((item, i) => {
        item.tag_list.forEach((list, j) => {
		        this.$forceUpdate()
          if (item.tag_group_id == tag_group_id && list.tag_id == tag_id) {
            this.$set(list, 'tagPop', true)
          } else {
            this.$set(list, 'tagPop', false)
          }
        })
      })
    },
    resetTagName (taglist) {
      this.$forceUpdate()
      taglist.resetTagValue = taglist.tag_name
      this.$set(taglist, 'tagNameReset', true)
    },
    warningClose () {
      this.warningDialog = false
    },

    // 获取标签组
    getTagGroup () {
      this.ifNodata = false
      this.$http({
        method: 'GET',
        url: '/tag/get_tag_group/'
      }).then(res => {
        if (res.data.status === 0) {
          if(res.data.data.length == 0){
            this.ifNodata = true
          }
          res.data.data.forEach(item => {
            item.nameReset = false // 标签组重命名 input显示隐藏控制
            item.editValue = ''
            item.editGroupSucc = false
            item.groupExistedTag = false
            item.innerShow = false
            item.tag_list.forEach((list, j) => {
              list.tagPop = false
              list.tagNameReset = false
              list.resetTagValue = ''
              list.resetTagSucc = false
              list.tagExistedTag = false
            })
          })
          this.tagGroupList = res.data.data
          // this.ifStayOpen()
        } else {

        }
      }).catch(error => console.log(error))
    },

    // 标签组添加
    addTagGroup () {
      const formData = new FormData()
      formData.append('tag_group_name', this.addValue.replace(/(^\s*)|(\s*$)/g, ''))
      this.$http({
        method: 'POST',
        url: '/tag/add_group/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {

          let obj = {
            tag_group_name: this.addValue.replace(/(^\s*)|(\s*$)/g, ''),
            tag_group_id: res.data.tag_group_id,
            nameReset: false,
            editValue: '',
            editGroupSucc: false,
            groupExistedTag: false,
            innerShow: false,
            tag_list:[]
          }
          this.tagGroupList.push(obj)
          sessionStorage.setItem("tag_group_id",res.data.tag_group_id)
          this.addGroupSucc = true
          this.addValue = '' //input框置空
          setTimeout(() => {
            this.addGroupSucc = false
          }, 3000)

        } else {
          this.ExistedTag = true
          this.errorWarn = '该标签组已存在'
          setTimeout(() => {
            this.ExistedTag = false
          }, 3000)
        }
      }).catch(error => console.log(error))
      this.$refs.saveTagInput.focus()
    },

    // 标签组重命名
    editGroup (item) {
      const formData = new FormData()
      formData.append('tag_group_id', item.tag_group_id)
      formData.append('tag_group_name', item.editValue.replace(/(^\s*)|(\s*$)/g, ''))
      this.$http({
        method: 'POST',
        url: '/tag/reset_tag_group_name/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          //标签组名修改
          for(let i = 0; i< this.tagGroupList.length; i++){
            if(this.tagGroupList[i].tag_group_id == item.tag_group_id){
               this.tagGroupList[i].tag_group_name = item.editValue.replace(/(^\s*)|(\s*$)/g, '')
               let obj = this.tagGroupList[i]
               this.tagGroupList.splice(i,1)
               this.tagGroupList.push(obj)
              //  console.log(obj)
            }
          }
          item.editGroupSucc = true
          item.nameReset = false
          setTimeout(() => {
            item.editGroupSucc = false
            // this.getTagGroup()
          }, 3000)
        } else {
          item.groupExistedTag = true
          item.nameReset = false
          this.editGroupWarn = '该标签组已存在'
          setTimeout(() => {
            // this.getTagGroup()
            item.groupExistedTag = false
          }, 3000)
        }
      }).catch(error => console.log(error))
    },

    delConfirm () {
      sessionStorage.removeItem("tag_group_id")
      // console.log(this.operDialog)
      switch (this.operDialog) {
        case 1:
          this.deleTagGroup()
          break
        case 2:
          this.tagIntoTagGroup()
          break
        case 3:
          this.deleteTagConfirm()
          break
      }
    },

    // 标签组删除
    deleTagGroup () {
      const formData = new FormData()
      formData.append('tag_group_id', this.deleTagGroupId)
      this.$http({
        method: 'POST',
        url: '/tag/delgroup/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.warningDialog = false

          //从数组中删除该标签组
          this.tagGroupList.forEach((item,i)=>{
            item.tag_group_id == this.deleTagGroupId && this.tagGroupList.splice(i,1)
          })

          // this.getTagGroup()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    // 修改/添加标签所在标签组
    tagIntoTagGroup () {
      const formData = new FormData()
      formData.append('tag_id', this.delTagId)
      formData.append('tag_group_id', this.deleTagGroupId)

      this.$http({
        method: 'POST',
        url: '/tag/update_taggroup/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.warningDialog = false

          this.tagGroupList.forEach((item,i)=>{
            if(item.tag_group_id == this.deleTagGroupId) {
              item.tag_list.forEach((item1,j)=>{
                item1.tag_id == this.delTagId && item.tag_list.splice(j,1)
              })
            }
          })

          // this.getTagGroup()

        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    // 标签删除
    deleteTagConfirm () {
      const formData = new FormData()
      formData.append('tag_id', this.delTagId)
      this.$http({
        method: 'POST',
        url: '/tag/deltag/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {
          this.warningDialog = false

          this.tagGroupList.forEach((item,i)=>{
            if(item.tag_group_id == this.deleTagGroupId) {
              item.tag_list.forEach((item1,j)=>{
                item1.tag_id == this.delTagId && item.tag_list.splice(j,1)
              })
            }
          })

          // this.getTagGroup()
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(error => console.log(error))
    },

    // 标签重命名
    sendResetTagName (taglist,tag_group_id) {
      this.deleTagGroupId = tag_group_id
      const formData = new FormData()
      formData.append('tag_id', taglist.tag_id)
      formData.append('tag_name', taglist.resetTagValue.replace(/(^\s*)|(\s*$)/g, ''))
      this.$http({
        method: 'POST',
        url: '/tag/reset_tag_name/',
        data: formData
      }).then(res => {
        if (res.data.status === 0) {

          this.tagGroupList.forEach((item,i)=>{
            if(item.tag_group_id == tag_group_id) {
              item.tag_list.forEach((item1,j)=>{
                if(item1.tag_id == taglist.tag_id){
                  item1.tag_name = taglist.resetTagValue.replace(/(^\s*)|(\s*$)/g, '')
                }
              })
            }
          })

          taglist.resetTagSucc = true
          taglist.tagNameReset = false
          taglist.tagPop = false
          setTimeout(() => {
            taglist.resetTagSucc = false
            // this.getTagGroup()
          }, 3000)
        } else {
          taglist.tagExistedTag = true
          taglist.tagNameReset = false
          this.editErrorWarn = '该标签已存在'

          setTimeout(() => {
            // this.getTagGroup()
            taglist.tagExistedTag = false
          }, 3000)
        }
      }).catch(error => console.log(error))
    },
    // ifStayOpen() {
    //   // 保持展开状态
    //   this.tagGroupList.forEach(item1 => {
    //     item1.tag_group_id == this.deleTagGroupId ? item1.innerShow = true : item1.innerShow = false
    //   })
    //   //保持展开状态
    // },

     // 标签滚动
    goAnchor(selector) {
      console.log(selector)
      console.log(this.$el)
      this.$el.querySelector(selector).scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
      });
    }

  },

  destroyed () {
    this.$store.commit('tagGroupPage', false)
    sessionStorage.removeItem("tag_group_id")
  }
}
</script>

<style lang="scss" scoped>
.tag_group{
  margin: 60px 75px 0 75px;
  .top_add_tag{
    height: 52px;
  }
  .add_tag{
    padding: 6px 14px;
    border-radius: 16px;
    border: none;
    background: #2D2C39;
    color: rgba(255,255,255,0.9);
    float: right;
    margin-bottom: 20px;
  }
  .add_tag:focus, .add_tag:hover{
    color: #409EFF;
  }
  .outer_edit{
    position:relative;
    text-align:right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .input-new-tag{
    width: 177px;
    margin-left: 9px;
    vertical-align: bottom;
    border-radius: 6px;
    margin-bottom: 20px;
    /deep/.el-input__inner{
      border: 1px solid #5E5F72;
      background-color: #0F0F1E;
      color: #fff;
    }
  }
  .category_tag{
    font-size: 12px;
    line-height: 16px;
    border-radius: 2px;
    padding: 5px 9px;
    position: absolute;
    box-sizing: border-box;
    right: 137px;
    top: -36px;
    white-space: nowrap;
  }
  .existed_tag{
    background-color: #fde2e2;
    color: #f56c6c;
  }
  .success_tag{
    background-color: #2A9CFF;
    color: #fff;
    right: 125px;
  }
  .operation{
    display: inline-block;
    font-size: 20px;
    margin-bottom: 20px;
    .iconfont{
      cursor: pointer;
      font-size: 20px;
      margin-left: 10px;
    }
    .icon-duihao{
      color: #69BA63;
      transform: rotate(0deg);
      &:hover{
        color: #5FED55
      }
    }
    .icon-cuo{
      color: #894141;
      &:hover{
        color: #D54B4B;
      }
    }

  }

  .tag_content{
    width: 100%;
    height: 67.3vh;
    clear: both;
    border: 1px solid #3E3D4C;
    border-radius: 6px;
    padding: 30px 0 0 30px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    p{
      position: relative;
      &:hover{
        .tag_group_operation{
          display: block;
        }
      }
      .tag_group_operation{
        display: none;
        position: absolute;
        top: 0;
        right: -62px;
        background-color: #2D2D3D;
        border-radius: 2px;
        white-space: nowrap;
        z-index: 999999;
        p{
          cursor: pointer;
          padding: 7px 10px;
          font-size: 14px;
          line-height: 14px;
          color: rgba(255,255,255,0.9);
          &:hover{
            background-color: rgba(42,156,255,0.3);
          }
        }
      }
    }
      .tag_list{
        overflow: visible;
        // height:48px;
      }
      .tag{
        display: inline-block;
        position: relative;
        .tag_title{
          padding: 6px 10px;
          background-color: rgba(62, 61, 76, 0.6);
          display: inline-block;
          border-radius: 6px;
          margin-right: 15px;
          margin-bottom: 15px;
          cursor: pointer;
          position: relative;
          white-space: pre;
          white-space: pre-wrap;
          color: rgba(255,255,255,0.8);
          &:hover{
            color: #409EFF;
          }

        }
        .pop_list{
          position: absolute;
          background-color: #2D2D3D;
          border-radius: 2px;
          right: -145px;
          top: 12px;
          z-index: 999;
          width: 144px;
          p{
            color: rgba(255,255,255,0.9);
            padding: 7px 9px;
            &:hover{
              background-color: rgba(42,156,255,0.3);
            }
          }
        }
      }
      &::-webkit-scrollbar {
          width : 6px;
      }
      &::-webkit-scrollbar-thumb {
          border-radius: 7px;
          box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #B9BAC9;
      }
      &::-webkit-scrollbar-track {
          box-shadow: unset;
          background:#5E5D6E;
          border-radius: 7px;
      }
      @media screen and (min--moz-device-pixel-ratio:0) {
          scrollbar-width: thin;
      }
  }
}
  .outer_title_box{
    color: rgba(255, 255, 255, 0.9);

    .el-icon-arrow-right{
      font-size: 18px;
      margin-right: 14px;
    }
    .outer_title{
      font-size: 16px;
      display: inline-block;
      height: 48px;
      line-height: 48px;
      padding-right: 10px;
      white-space: pre;
      white-space: pre-wrap;
      &:hover{
      color: #409EFF;
      cursor: pointer;
    }
    }

  }
  .is-active{
    i{
      transform: rotate(90deg);
    }
  }
</style>
<style lang="scss">
  .el-dialog.delete-dialog{
      background-color: #292939;
      border-radius: 15px;
      box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
      padding: 8px 21px 21px;
      .el-dialog__header{
        text-align: center;
        padding: 0;
        padding-bottom: 6px;
        .el-dialog__title{
          color: rgba(255,255,255,0.9);
          font-size: 16px;
          letter-spacing: 3px;
        }
        .el-dialog__headerbtn{
          top: 10px;
          .el-dialog__close{
            color: #fff;
            font-size: 18px;
          }
        }
      }
      .el-dialog__body{
        color: rgba(255,255,255,0.9);
        background-color: #0F0F1E;
        font-size: 14px;
        padding: 38px 62px 0 62px;
      }
      .el-dialog__footer{
        padding: 20px 62px 36px 62px;
        background-color: #0F0F1E;
        border-radius: 0px 0px 10px 10px;
        .dialog-footer{
          display: flex;
          justify-content: space-between;
          .el-button{
            border-radius: 20px;
            border: 1px solid #5E5F72;
            background-color: #0F0F1E;
            color: rgba(255,255,255,0.9);
            padding: 6px 15px;
          }
        }
      }
  }
</style>
