var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag_group" },
    [
      _c(
        "div",
        { staticClass: "top_add_tag" },
        [
          !_vm.editVisible
            ? _c(
                "el-button",
                { staticClass: "add_tag", on: { click: _vm.onAddTagGroup } },
                [_vm._v("添加")]
              )
            : _c(
                "div",
                { staticClass: "outer_edit" },
                [
                  _c("transition", { attrs: { name: "el-fade-in" } }, [
                    _vm.ExistedTag
                      ? _c("div", { staticClass: "existed_tag category_tag" }, [
                          _vm._v(_vm._s(_vm.errorWarn))
                        ])
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "el-fade-in" } }, [
                    _vm.addGroupSucc
                      ? _c("div", { staticClass: "success_tag category_tag" }, [
                          _vm._v("标签组添加成功！")
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        directives: [{ name: "focus", rawName: "v-focus" }],
                        ref: "saveTagInput",
                        staticClass: "input-new-tag",
                        attrs: {
                          size: "small",
                          placeholder: "添加标签组",
                          maxlength: 20
                        },
                        nativeOn: {
                          keyup: function($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.onConfirm($event)
                          }
                        },
                        model: {
                          value: _vm.addValue,
                          callback: function($$v) {
                            _vm.addValue = $$v
                          },
                          expression: "addValue"
                        }
                      })
                    ],
                    1
                  ),
                  _c("div", { staticClass: "operation" }, [
                    _c("i", {
                      staticClass: "iconfont icon-duihao",
                      on: { click: _vm.onConfirm }
                    }),
                    _c("i", {
                      staticClass: "iconfont icon-cuo",
                      on: { click: _vm.onCancel }
                    })
                  ])
                ],
                1
              )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tag_content" },
        [
          _vm._l(_vm.tagGroupList, function(item, i) {
            return _c("div", { key: i }, [
              _c(
                "div",
                {
                  class: ["outer_title_box", item.innerShow ? "is-active" : ""],
                  attrs: { id: "box" + item.tag_group_id }
                },
                [
                  !item.nameReset
                    ? _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showInnerTag(item)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "el-icon-arrow-right" }),
                          _c(
                            "p",
                            {
                              staticClass: "outer_title",
                              attrs: { id: item.tag_group_id },
                              on: {
                                mouseenter: function($event) {
                                  return _vm.tagOper(item.tag_group_id)
                                }
                              }
                            },
                            [
                              _vm._v(_vm._s(item.tag_group_name) + " "),
                              _c(
                                "span",
                                { staticClass: "tag_group_operation" },
                                [
                                  _c(
                                    "p",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.resetName(
                                            item.tag_group_id,
                                            item.tag_group_name
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("重命名")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteGroup(
                                            item.tag_group_id,
                                            "",
                                            1
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("删除")]
                                  )
                                ]
                              ),
                              _c(
                                "transition",
                                { attrs: { name: "el-fade-in" } },
                                [
                                  item.groupExistedTag
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "existed_tag category_tag",
                                          staticStyle: {
                                            left: "0",
                                            right: "auto",
                                            top: "-20px"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.editGroupWarn))]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "transition",
                                { attrs: { name: "el-fade-in" } },
                                [
                                  item.editGroupSucc
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "success_tag category_tag",
                                          staticStyle: {
                                            left: "0",
                                            right: "auto",
                                            top: "-20px"
                                          }
                                        },
                                        [_vm._v("标签组重命名成功！")]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            position: "relative",
                            "text-align": "left",
                            "line-height": "48px",
                            display: "inline-block"
                          }
                        },
                        [
                          _c("el-input", {
                            directives: [{ name: "focus", rawName: "v-focus" }],
                            ref: "resetTagInput",
                            refInFor: true,
                            staticClass: "input-new-tag",
                            staticStyle: {
                              "line-height": "48px",
                              "margin-bottom": "0",
                              width: "140px"
                            },
                            attrs: { size: "small", maxlength: 20 },
                            nativeOn: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.onFocus($event)
                              },
                              keyup: [
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.onConfirmGroup(item)
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.stopPropagation()
                                  return _vm.onFocus($event)
                                }
                              ],
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.onFocus($event)
                              },
                              keyenter: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.onFocus($event)
                              }
                            },
                            model: {
                              value: item.editValue,
                              callback: function($$v) {
                                _vm.$set(item, "editValue", $$v)
                              },
                              expression: "item.editValue"
                            }
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "operation",
                              staticStyle: {
                                "line-height": "48px",
                                "vertical-align": "middle",
                                "margin-bottom": "0"
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "iconfont icon-duihao",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onConfirmGroup(item)
                                  }
                                }
                              }),
                              _c("i", {
                                staticClass: "iconfont icon-cuo",
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.onCancelGroup(item)
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                  item.innerShow && item.tag_list.length
                    ? _c(
                        "div",
                        { staticClass: "tag_list" },
                        _vm._l(item.tag_list, function(taglist, j) {
                          return _c("div", { key: j, staticClass: "tag" }, [
                            !taglist.tagNameReset
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "tag_title",
                                    attrs: { id: taglist.tag_id },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.tagClick(
                                          item.tag_group_id,
                                          taglist.tag_id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(taglist.tag_name) +
                                        "（" +
                                        _vm._s(taglist.count) +
                                        "） "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: taglist.tagPop,
                                            expression: "taglist.tagPop"
                                          }
                                        ],
                                        staticClass: "pop_list"
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteGroup(
                                                  item.tag_group_id,
                                                  taglist.tag_id,
                                                  2
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("从该组移除")]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.resetTagName(taglist)
                                              }
                                            }
                                          },
                                          [_vm._v("重命名")]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteGroup(
                                                  item.tag_group_id,
                                                  taglist.tag_id,
                                                  3
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("删除")]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "transition",
                                      { attrs: { name: "el-fade-in" } },
                                      [
                                        taglist.tagExistedTag
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "existed_tag category_tag",
                                                staticStyle: {
                                                  left: "0",
                                                  right: "auto",
                                                  top: "-31px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.editErrorWarn)
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    _c(
                                      "transition",
                                      { attrs: { name: "el-fade-in" } },
                                      [
                                        taglist.resetTagSucc
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "success_tag category_tag",
                                                staticStyle: {
                                                  left: "0",
                                                  right: "auto",
                                                  top: "-31px"
                                                }
                                              },
                                              [_vm._v("标签重命名成功！")]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      "text-align": "right",
                                      "line-height": "32px",
                                      "margin-right": "15px"
                                    }
                                  },
                                  [
                                    _c("el-input", {
                                      directives: [
                                        { name: "focus", rawName: "v-focus" }
                                      ],
                                      ref: "saveTagInput",
                                      refInFor: true,
                                      staticClass: "input-new-tag",
                                      staticStyle: {
                                        "line-height": "32px",
                                        "margin-bottom": "0",
                                        width: "140px",
                                        "margin-left": "0"
                                      },
                                      attrs: { size: "small", maxlength: 20 },
                                      nativeOn: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.onFocus($event)
                                        },
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.onConfirmTag(
                                            taglist,
                                            item.tag_group_id
                                          )
                                        },
                                        keydown: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                          return _vm.onFocus($event)
                                        },
                                        keyenter: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          $event.stopPropagation()
                                          return _vm.onFocus($event)
                                        }
                                      },
                                      model: {
                                        value: taglist.resetTagValue,
                                        callback: function($$v) {
                                          _vm.$set(
                                            taglist,
                                            "resetTagValue",
                                            $$v
                                          )
                                        },
                                        expression: "taglist.resetTagValue"
                                      }
                                    }),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "operation",
                                        staticStyle: {
                                          "line-height": "32px",
                                          "vertical-align": "middle",
                                          "margin-bottom": "0"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "iconfont icon-duihao",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onConfirmTag(
                                                taglist,
                                                item.tag_group_id
                                              )
                                            }
                                          }
                                        }),
                                        _c("i", {
                                          staticClass: "iconfont icon-cuo",
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onCancelTag(taglist)
                                            }
                                          }
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ]
              )
            ])
          }),
          _vm.ifNodata
            ? _c("noResult", {
                attrs: {
                  iconUrl: "icon-meiyousucai",
                  iconSty: "font-size:80px",
                  textSty: "font-size:18px",
                  noResultText: "暂无数据"
                }
              })
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提 示",
            visible: _vm.warningDialog,
            width: "400px",
            top: "30vh",
            "custom-class": "delete-dialog",
            "show-close": true,
            "before-close": _vm.warningClose
          },
          on: {
            "update:visible": function($event) {
              _vm.warningDialog = $event
            }
          }
        },
        [
          _c("div", { staticClass: "warning_content" }, [
            _c("div", [_vm._v(" " + _vm._s(_vm.dialogWarning) + " ")])
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.delConfirm } },
                [_vm._v("是")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.warningDialog = false
                    }
                  }
                },
                [_vm._v("否")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }